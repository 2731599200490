<template>
  <div :id="id" :style="{ height: height, width: width }"></div>
</template>

<script>
import * as echarts from "echarts"

export default {
  name: "bar_total",
  props: {
    id: {
      type: String,
      default: "agechart",
    },
    title: {
      type: String,
      default: "就业年龄分布",
    },
    xAxis: {
      type: Array,
      default: () => [
        "18-25",
        "26-36",
        "36-40",
        "40-45",
        "45-50",
        "50-55",
        "55-60",
      ],
    },
    yAxis1: {
      type: Array,
      // default: () => [135, 631, 524, 538, 211, 0, 0],
      default: () => [361, 1475, 1117, 1179, 1110, 1446, 659],
    },
    yAxis2: {
      type: Array,
      default: () => [226, 844, 593, 641, 899, 1446, 659],
    },
    yName1: {
      type: Array,
      default: () => ["女", "男"],
    },
    width: {
      type: String,
      default: "100%",
    },
    height: {
      type: String,
      default: "2.5rem",
    },
  },
  data() {
    return {}
  },
  mounted() {
    this.ageOptions()
  },
  methods: {
    ageOptions() {
      var chartDom = document.getElementById(this.id)
      var myChart = echarts.init(chartDom)
      var option = {
        tooltip: {
          trigger: "axis",
          axisPointer: {
            type: "shadow",
          },
        },

        grid: {
          left: "8%",
          right: "2%",
          bottom: "3%",
          top: "17%",
          containLabel: true,
        },
        color: ["#4DBEFF", "#2450C0"],
        legend: {
          show: false,
          data: this.yName1,
          textStyle: {
            color: "#fff",
          },
          top: "5%",
          icon: "circle",
        },
        xAxis: {
          type: "category",

          // splitLine: {
          //   show: true,
          //   lineStyle: {
          //     color: "#fff",
          //   },
          // },

          axisLabel: {
            show: true,
            lineStyle: {
              color: "#fff",
            },
            rotate: 30,
          },

          axisLine: {
            show: true,
            lineStyle: {
              color: "#fff",
            },
          },
          axisTick: {
            show: false,
          },
          data: this.xAxis,
        },
        yAxis: [
          {
            // name: "人",
            type: "value",

            // inverse: true,
            axisLine: {
              show: false,
              lineStyle: {
                color: "#fff",
              },
            },
            splitLine: {
              show: false,
              lineStyle: {
                color: "#fff",
              },
            },
            axisTick: {
              show: false,
            },

            axisLabel: {
              show: false,
              // margin: 30,
              fontSize: 12,
              align: "left",
              padding: [3, 0, 0, 0],
              color: "#3DE7C9",
            },
          },
          // {
          //   type: "category",
          //   inverse: true,
          //   axisTick: "none",
          //   axisLine: "none",
          //   show: true,
          //   axisLabel: {
          //     // textStyle: {
          //     //   color: "#3DE7C9",
          //     //   fontSize: "14",
          //     // },
          //   },
          //   data: this.dataFormat(this.values),
          // },

          // {
          //   // 条状标题
          //   type: "category",
          //   inverse: true,
          //   offset: 15,
          //   position: "left",
          //   axisTick: "none",
          //   axisLine: "none",
          //   show: true,
          //   axisLabel: {
          //     interval: 0,
          //     color: ["#fff"],
          //     align: "left",
          //     verticalAlign: "bottom",
          //     lineHeight: 20,
          //     fontSize: 14,
          //   },
          //   data: this.dataFormat(this.stationData),
          // },
        ],
        series: [
          {
            zlevel: 1,
            name: this.yName1[0],
            type: "bar",
            barWidth: 12,
            data: this.dataFormat(this.yAxis1),
            // align: "center",
            itemStyle: {
              normal: {
                // barBorderRadius: 10,
                barBorderRadius: [10, 10, 10, 10],
              },
            },
            label: {
              //条状中的样式
              show: true,
              fontSize: 13,
              position: "top",
              color: "#fff", //条装中字体颜色
              // textBorderWidth: 2,
              // padding: [2, 0, 0, 0],
            },
          },
          // {
          //   name: this.yName1[1],
          //   type: "bar",
          //   barWidth: 16,
          //   barGap: "-100%",
          //   data: this.yAxis2,
          //   itemStyle: {
          //     normal: {
          //       color: "rgba(43,162,206,.4)",
          //       //width:"100%",
          //       fontSize: 12,
          //       barBorderRadius: [10, 10, 10, 10],
          //       // barBorderRadius: 30,
          //     },
          //   },
          //   label: {
          //     //条状中的样式
          //     show: true,
          //     fontSize: 13,
          //     position: "top",
          //     // textAlign: "right",
          //     color: "#fff", //条装中字体颜色
          //     textBorderWidth: 2,
          //     // padding: [2, 0, 0, 0],
          //   },
          // },
        ],
      }
      myChart.setOption(option)
      window.addEventListener("resize", function() {
        myChart.resize() //下面可以接多个图
      })
    },
    dataFormat(data) {
      var arr = []
      data.forEach((item) => {
        let itemStyle = {
          // color: this.attackSourcesColor[i],
          color: "#4DBEFF",
        }
        arr.push({
          value: item,
          itemStyle: itemStyle,
        })
      })
      return arr
    },
  },
}
</script>

<style lang="scss" scoped></style>
