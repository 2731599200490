<template>
  <div :id="id" :style="{ height: height, width: width }"></div>
</template>

<script>
import * as echarts from "echarts"

export default {
  name: "bar_total",
  props: {
    id: {
      type: String,
      default: "agechart",
    },
    title: {
      type: String,
      default: "就业年龄分布",
    },
    xAxis: {
      type: Array,
      default: () => [
        "就业需求",
        "康复需求",
        "保洁需求",
        "心理支持",
        "劳动产品制作培训需求",
        "转介服务需求",
      ],
    },
    yAxis1: {
      type: Array,
      default: () => [25, 20, 15, 5, 10, 15],
    },
    yAxis2: {
      type: Array,
      default: () => [20, 15, 14, 5, 9, 15],
    },
    yName1: {
      type: String,
      default: "女",
    },
    yName2: {
      type: String,
      default: "男",
    },
    width: {
      type: String,
      default: "100%",
    },
    height: {
      type: String,
      default: "2.5rem",
    },
  },
  data() {
    return {}
  },
  mounted() {
    this.ageOptions()
  },
  methods: {
    ageOptions() {
      var chartDom = document.getElementById(this.id)
      var myChart = echarts.init(chartDom)
      var yData = []
      for (var i = 0; i < this.xAxis.length; i++) {
        yData.push(this.xAxis[i])
      }
      var option = {
        tooltip: {
          trigger: "axis",
          axisPointer: {
            type: "shadow",
          },
        },
        grid: {
          left: "-40%",
          right: "15%",
          bottom: "-10%",
          top: "15%",
          containLabel: true,
        },
        xAxis: [
          {
            show: false,
          },
          {
            show: false,
          },
        ],
        yAxis: {
          // name: "人",
          type: "category",
          inverse: true,
          nameGap: 16,
          axisLine: {
            show: false,
          },
          axisTick: {
            show: false,
          },
          axisLabel: {
            show: true,
            padding: [-30, 0, 0, 10],
            align: "top",
            textStyle: {
              color: "#fff",
            },
          },
          data: yData,
        },

        series: [
          //亮色条 百分比
          {
            name: "已完成",
            show: true,
            type: "bar",
            barGap: "-100%",
            barWidth: 12,
            z: 2,
            silent: true,
            itemStyle: {
              barBorderRadius: 12,
              color: {
                type: "linear",
                x: 0,
                y: 0,
                x2: 1,
                y2: 0,
                colorStops: [
                  // {
                  //   offset: 0,
                  //   color: "rgba(252,114,147,0.5)", // 0% 处的颜色
                  // },
                  {
                    offset: 1,
                    color: "rgba(252,114,147,1)", // 100% 处的颜色
                  },
                ],
              },
            },
            label: {
              normal: {
                show: true,
                // position: [0, "-10"],
                textStyle: {
                  fontSize: 12,
                  color: "#fff",
                  lineHeight: -20,
                },
                // formatter: function(data) {
                //   var result = ""
                //   result += chartName[data.dataIndex]
                //   return result
                // },
                rich: {
                  blue: {
                    fontSize: 12,
                    lineHeight: 5,
                    color: "#62E6F6",
                  },
                  mr: {
                    padding: [0, 0, 0, 10],
                    fontSize: 12,
                    color: "#fff",
                  },
                },
              },
            },
            data: this.yAxis2,
          },
          {
            name: "总量",
            type: "bar",
            itemStyle: {
              normal: {
                barBorderRadius: 12,
                color: {
                  type: "linear",
                  x: 0,
                  y: 0,
                  x2: 1,
                  y2: 0,
                  colorStops: [
                    {
                      offset: 0,
                      color: "rgba(51,197,233,0.5)", // 0% 处的颜色
                    },
                    {
                      offset: 1,
                      color: "rgba(51,197,233,1)", // 100% 处的颜色
                    },
                  ],
                },
              },
            },
            label: {
              normal: {
                show: true,
                textStyle: {
                  color: "#fff",
                  fontSize: 12,
                  lineHeight: 0,
                },
                position: "right",
                // position: ["95%", "-50%"],
                rich: {
                  blue: {
                    fontSize: 12,
                    lineHeight: 5,
                    color: "#62E6F6",
                  },
                },
              },
            },
            barGap: "-100%",
            z: 0,
            barWidth: 12,
            data: this.yAxis1,
          },
          //年份
          // {
          //   show: true,
          //   type: "bar",
          //   xAxisIndex: 1, //代表使用第二个X轴刻度
          //   barGap: "-100%",
          //   barWidth: 10,
          //   itemStyle: {
          //     normal: {
          //       barBorderRadius: 0,
          //       color: "transparent",
          //     },
          //   },
          //   label: {
          //     normal: {
          //       show: true,
          //       position: [0, "-10"],
          //       textStyle: {
          //         fontSize: 14,
          //         color: "#fff",
          //         lineHeight: -20,
          //       },
          //       formatter: function(data) {
          //         var result = ""
          //         result += chartName[data.dataIndex]
          //         return result
          //       },
          //       rich: {
          //         blue: {
          //           fontSize: 14,
          //           lineHeight: 5,
          //           color: "#62E6F6",
          //         },
          //         mr: {
          //           padding: [0, 0, 0, 10],
          //           fontSize: 14,
          //           color: "#fff",
          //         },
          //       },
          //     },
          //   },
          //   data: chartData,
          // },
        ],
      }
      myChart.setOption(option)
      window.addEventListener("resize", function() {
        myChart.resize() //下面可以接多个图
      })
    },
  },
}
</script>

<style lang="scss" scoped></style>
