<template>
  <div :id="id" :style="{ height: height, width: width }"></div>
</template>

<script>
import * as echarts from "echarts"

export default {
  name: "bar_emp",
  props: {
    id: {
      type: String,
      default: "employee",
    },
    title: {
      type: String,
      default: "全年目标/完成情况  2,678/25,000人/次",
    },
    xAxis: {
      type: Array,
      default: () => [
        "农村地区就业",
        "辅助性就业",
        "大学生就业",
        "公益岗残疾人",
        "公益岗家属",
      ],
    },
    yAxis1: {
      type: Array,
      default: () => [6223, 1482, 247, 268, 446],
    },
    yAxis2: {
      type: Array,
      default: () => [8000, 2000, 500, 500, 1000],
    },
    yName1: {
      type: Array,
      default: () => ["完成就业", "目标就业"],
    },
    width: {
      type: String,
      default: "100%",
    },
    height: {
      type: String,
      default: "360px",
    },
    xgrid: {
      type: Object,
      default: () => {
        return {
          top: "17%",
          right: "5%",
          left: "5%",
          bottom: "15%",
        }
      },
    },
  },
  components: {},
  data() {
    return {}
  },
  mounted() {
    this.dangjian()
  },
  methods: {
    dangjian() {
      var chartDom = document.getElementById(this.id)
      var myChart = echarts.init(chartDom)
      // Generate data

      var xAxis_data = [
        "北京",
        "上海",
        "浙江",
        "四川",
        "天津",
        "湖北省",
        "重庆",
        "山东",
        "江苏",
        "辽宁省",
        "陕西",
        "福建",
        "河南",
        "广东",
        "河北",
        "湖南省",
        "云南",
        "内蒙古",
        "安徽",
        "黑龙江省",
        "西藏",
        "新疆",
        "吉林省",
        "江西省",
        "广西",
        "贵州",
        "海南",
        "山西",
      ]
      var data_A = [
        6,
        0,
        0,
        0,
        0,
        0,
        0,
        0,
        0,
        0,
        0,
        0,
        0,
        0,
        0,
        0,
        0,
        0,
        0,
        0,
        0,
        0,
        0,
        0,
        0,
        0,
        0,
        0,
      ]
      var data_B = [
        179,
        34,
        67,
        6,
        6,
        4,
        2,
        2,
        5,
        2,
        1,
        1,
        1,
        1,
        4,
        1,
        1,
        1,
        1,
        1,
        1,
        1,
        1,
        1,
        1,
        1,
        1,
        1,
      ]
      var data_C = [
        3086,
        0,
        0,
        0,
        0,
        0,
        0,
        0,
        0,
        0,
        0,
        0,
        0,
        0,
        0,
        0,
        0,
        0,
        0,
        0,
        0,
        0,
        0,
        0,
        0,
        0,
        0,
        0,
      ]
      var data_D = [
        4737,
        683,
        469,
        418,
        242,
        236,
        178,
        122,
        83,
        79,
        69,
        62,
        46,
        44,
        34,
        26,
        25,
        19,
        17,
        17,
        15,
        9,
        8,
        8,
        5,
        5,
        5,
        4,
      ]
      var names = ["直营", "加盟", "直营服务残疾人数", "加盟服务残疾人数"]
      var color = ["#4D8EFF", "#53C2FF", "#FFAA00", "#C7E7FF"]

      var option = {
        tooltip: {
          trigger: "axis",
          //formatter: '{b}<br />{a2}:{c2}%<br />{a1}:{c1}%<br />{a0}:{c0}%'
          formatter: function(params) {
            var htmlStr = ""
            for (var i = 0; i < params.length; i++) {
              var param = params[i]
              var xName = param.name //x轴的名称
              var seriesName = param.seriesName //图例名称
              var value = param.value //y轴值
              var color = param.color //图例颜色
              if (i === 0) {
                htmlStr += xName + "<br/>" //x轴的名称
              }
              htmlStr += "<div>"
              htmlStr +=
                '<span style="margin-right:5px;display:inline-block;width:10px;height:10px;border-radius:5px;background-color:' +
                color +
                ';"></span>' //一个点
              htmlStr += seriesName + "：" + value + "" //圆点后面显示的文本
              htmlStr += "</div>"
            }
            return htmlStr
          },
        },
        grid: {
          //页边距
          top: "5%",
          left: "1%",
          right: "1%",
          bottom: "1%",
          containLabel: true,
        },
        dataZoom: [
          {
            type: "inside",
            start: 0,
            end: 20,
          },
          {
            show: true,
            type: "slider",
            bottom: 20,
            start: 0,
            end: 100,
          },
        ],
        legend: {
          // 图例
          show: false,
          top: "5%",
          // right:'20%',
          data: names,
          formatter: function(name) {
            return name
          },
        },

        xAxis: {
          type: "category",
          data: xAxis_data,
          axisLine: {
            //坐标线
            show: false,
            lineStyle: {
              type: "solid",
              color: "#fff", //轴线的颜色
              width: "1", //坐标线的宽度
            },
          },
          axisLabel: {
            formatter: "{value}",
            textStyle: {
              fontSize: 14,
              lineHeight: 17,
              color: "#fff", //坐标值的具体的颜色
              opacity: 1,
            },
          },
          axisTick: {
            show: false,
          },
        },
        yAxis: [
          {
            type: "value",
            axisLine: {
              //线
              show: false,
            },
            axisTick: {
              //刻度
              show: false,
            },
            axisLabel: {
              show: false,
              formatter: "{value}",
              textStyle: {
                fontSize: 12,
                fontFamily: "PingFang SC",
                fontWeight: 400,
                lineHeight: 17,
                color: "#fff", //坐标值的具体的颜色
                opacity: 1,
              },
            },
            splitLine: {
              lineStyle: {
                type: "solid",
                width: 1,
                color: "rgba(255,255,255,0.1)", //分割线的颜色
                // color: ['#5d5d5d'] //分割线的颜色
              },
            },
          },
          {
            type: "value",
            axisLine: {
              //线
              show: false,
            },
            axisTick: {
              //刻度
              show: false,
            },
            axisLabel: {
              show: false,
              formatter: "{value}",
              textStyle: {
                fontSize: 12,
                fontFamily: "PingFang SC",
                fontWeight: 400,
                lineHeight: 17,
                color: "#fff", //坐标值的具体的颜色
                opacity: 1,
              },
            },
            splitLine: {
              lineStyle: {
                type: "solid",
                width: 1,
                color: "rgba(255,255,255,0.1)", //分割线的颜色
                // color: ['#5d5d5d'] //分割线的颜色
              },
            },
          },
        ],
        series: [
          {
            type: "bar",
            barWidth: "8%",
            name: names[0],
            data: data_A,
            itemStyle: {
              normal: {
                barBorderRadius: [50, 50, 0, 0],
                color: color[0], //设置颜色
                lineStyle: {
                  width: 0,
                  type: "solid", //'dotted'虚线 'solid'实线
                },
              },
            },
          },
          {
            type: "bar",
            barWidth: "8%",
            name: names[1],
            data: data_B,
            itemStyle: {
              normal: {
                barBorderRadius: [50, 50, 0, 0],
                color: color[1], //设置颜色
                lineStyle: {
                  width: 0,
                  type: "solid", //'dotted'虚线 'solid'实线
                },
              },
            },
          },
          {
            type: "line",
            yAxisIndex: 1,
            smooth: true,
            name: names[2],
            data: data_C,
            lineStyle: {
              normal: {
                color: "#00b3f4",
                shadowColor: "rgba(0, 0, 0, .3)",
                shadowBlur: 0,
                shadowOffsetY: 5,
                shadowOffsetX: 5,
              },
            },
            symbolSize: 5, //设置拐点大小
            areaStyle: {
              color: {
                type: "linear",
                x: 0,
                y: 0,
                x2: 0,
                y2: 1,
                colorStops: [
                  {
                    offset: 0,
                    color: "rgba(0,179,244,.3)", // 0% 处的颜色
                  },
                  {
                    offset: 1,
                    color: "rgba(0,179,244,0)", // 100% 处的颜色
                  },
                ],
                shadowColor: "rgba(0,179,244, 0.9)",
                // global: false, // 缺省为 false
              },
            },
            color: color[2], //设置颜色
            itemStyle: {
              normal: {
                lineStyle: {
                  width: 1,
                  type: "solid", //'dotted'虚线 'solid'实线
                },
              },
            },
          },
          {
            type: "line",
            yAxisIndex: 1,
            smooth: true,
            name: names[3],
            data: data_D,
            lineStyle: {
              normal: {
                color: "#23C994",
                shadowColor: "rgba(0, 0, 0, .3)",
                shadowBlur: 0,
                shadowOffsetY: 5,
                shadowOffsetX: 5,
              },
            },
            symbolSize: 5, //设置拐点大小
            areaStyle: {
              color: {
                type: "linear",
                x: 0,
                y: 0,
                x2: 0,
                y2: 1,
                colorStops: [
                  {
                    offset: 0,
                    color: "rgba(35,201,148,.3)", // 0% 处的颜色
                  },
                  {
                    offset: 1,
                    color: "rgba(35,201,148,0)", // 100% 处的颜色
                  },
                ],
                shadowColor: "rgba(35,201,148, 0.9)",
                // global: false, // 缺省为 false
              },
            },
            color: color[2], //设置颜色
            itemStyle: {
              normal: {
                lineStyle: {
                  width: 1,
                  type: "solid", //'dotted'虚线 'solid'实线
                },
              },
            },
          },
        ],
      }

      myChart.setOption(option)
      window.addEventListener("resize", function() {
        myChart.resize() //下面可以接多个图
      })

      var faultByHourIndex = 0 //播放所在下标
      setInterval(function() {
        //使得tootip每隔三秒自动显示
        myChart.dispatchAction({
          type: "showTip", // 根据 tooltip 的配置项显示提示框。
          seriesIndex: 0,
          dataIndex: faultByHourIndex,
        })
        faultByHourIndex++
        // faultRateOption.series[0].data.length 是已报名纵坐标数据的长度
        if (faultByHourIndex >= option.series[1].data.length) {
          faultByHourIndex = 0
        }
        // if (faultByHourIndex >= option.series[1].data.length) {
        //   faultByHourIndex = 0
        // }
        // if (faultByHourIndex >= option.series[2].data.length) {
        //   faultByHourIndex = 0
        // }
      }, 3000)
    },
  },
}
</script>

<style lang="scss" class></style>
