<template>
  <div :id="id" :style="{ height: height, width: width }"></div>
</template>

<script>
import * as echarts from "echarts"

export default {
  name: "bar_emp",
  props: {
    id: {
      type: String,
      default: "employee",
    },
    title: {
      type: String,
      default: "已完成/年度目标  8,716/12,000",
    },
    xAxis: {
      type: Array,
      default: () => [
        "农村公益劳动型帮扶性就业",
        "职康劳动型帮扶性就业",
        "帮扶性就业车间",
        "大学生到岗就业",
        "残疾人及家属外包",
      ],
    },
    yAxis1: {
      type: Array,
      default: () => [6223, 1532, 247, 268, 446],
    },
    yAxis2: {
      type: Array,
      default: () => [8000, 2000, 500, 500, 1000],
    },
    yName1: {
      type: Array,
      default: () => ["完成就业", "目标就业"],
    },
    width: {
      type: String,
      default: "100%",
    },
    height: {
      type: String,
      default: "360px",
    },
    xgrid: {
      type: Object,
      default: () => {
        return {
          top: "17%",
          right: "5%",
          left: "5%",
          bottom: "15%",
        }
      },
    },
  },
  components: {},
  data() {
    return {}
  },
  mounted() {
    this.dangjian()
  },
  methods: {
    dangjian() {
      var chartDom = document.getElementById(this.id)
      var myChart = echarts.init(chartDom)
      var option = {
        title: {
          text: this.title,
          textStyle: {
            fontSize: 14,
            color: "#fff",
          },
          right: "5%",
        },
        tooltip: {
          trigger: "axis",
          axisPointer: {
            type: "shadow",
          },
        },
        grid: this.xgrid,
        xAxis: [
          {
            type: "category",
            data: this.xAxis,
            axisLine: {
              lineStyle: {
                color: "rgba(255,255,255,0.12)",
              },
            },
            axisLabel: {
              margin: 10,
              color: "#e2e9ff",
              textStyle: {
                fontSize: 12,
              },
              interval: 0,
              formatter: function (value) {
                const first = value.slice(0, 5)
                const last = value.slice(5)
                return [first, last].join('\n')
              }
            },
          },
        ],
        yAxis: [
          {
            // name: "人",
            axisLabel: {
              show: false,
              formatter: "{value}",
              color: "#e2e9ff",
            },
            axisTick: {
              show: false,
            },
            axisLine: {
              show: false,
              lineStyle: {
                color: "rgba(255,255,255,1)",
              },
            },
            splitLine: {
              lineStyle: {
                color: "rgba(255,255,255,0.12)",
              },
            },
          },
        ],
        series: [
          {
            type: "bar",
            name: this.yName1[0],
            data: this.yAxis1,
            barWidth: "10px",
            itemStyle: {
              normal: {
                color: new echarts.graphic.LinearGradient(
                  0,
                  0,
                  0,
                  1,
                  [
                    {
                      offset: 0,
                      color: "rgba(0,244,255,1)", // 0% 处的颜色
                    },
                    {
                      offset: 1,
                      color: "rgba(0,77,167,1)", // 100% 处的颜色
                    },
                  ],
                  false
                ),
                barBorderRadius: [10, 10, 10, 10],
                shadowColor: "rgba(0,160,221,1)",
                shadowBlur: 4,
              },
            },
            label: {
              normal: {
                show: true,
                lineHeight: 20,
                width: 80,
                height: 20,
                backgroundColor: "rgba(0,160,221,0.1)",
                borderRadius: 200,
                position: ["-48", "-30"],
                distance: 1,
                formatter: ["    {d|●}", " {a|{c}}     \n", "    {b|}"].join(
                  ""
                ),
                rich: {
                  d: {
                    color: "#3CDDCF",
                  },
                  a: {
                    color: "#fff",
                    align: "center",
                    fontSize: "14",
                  },
                  // b: {
                  //   width: 1,
                  //   height: 15,
                  //   borderWidth: 1,
                  //   borderColor: "#234e6c",
                  //   align: "left",
                  // },
                },
              },
            },
          },
          {
            type: "bar",
            name: this.yName1[1],
            data: this.yAxis2,
            barWidth: "10px",
            itemStyle: {
              normal: {
                color: new echarts.graphic.LinearGradient(
                  0,
                  0,
                  0,
                  1,
                  [
                    {
                      offset: 0,
                      color: "rgba(235,215,255,1)", // 0% 处的颜色
                    },
                    {
                      offset: 1,
                      color: "rgba(235,215,255,0.4)", // 100% 处的颜色
                    },
                  ],
                  false
                ),
                barBorderRadius: [10, 10, 10, 10],
                shadowColor: "rgba(235,215,255,1)",
                shadowBlur: 4,
              },
            },
            label: {
              normal: {
                show: true,
                lineHeight: 20,
                width: 80,
                height: 20,
                backgroundColor: "rgba(235,215,255,0.3)",
                borderRadius: 200,
                position: ["-14", "-40"],
                distance: 1,
                formatter: ["    {d|●}", " {a|{c}}     \n", "    {b|}"].join(
                  ""
                ),
                rich: {
                  d: {
                    color: "#EBD7FF",
                  },
                  a: {
                    color: "#EBD7FF",
                    align: "center",
                    fontSize: "14",
                  },
                  // b: {
                  //   width: 1,
                  //   height: 15,
                  //   borderWidth: 1,
                  //   borderColor: "#234e6c",
                  //   align: "left",
                  // },
                },
              },
            },
          },
        ],
      }
      myChart.setOption(option)
      window.addEventListener("resize", function() {
        myChart.resize() //下面可以接多个图
      })
    },
  },
}
</script>

<style lang="scss" class></style>
