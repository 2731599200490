<template>
  <div id="index" ref="appRef">
    <div class="bg">
      <dv-loading v-if="loading">Loading...</dv-loading>
      <div v-else class="host-body">
        <div class="d-flex jc-center" style="height:2.5vh">
          <dv-decoration-10 class="dv-dec-10" />
          <div class="d-flex jc-center">
            <dv-decoration-8 class="dv-dec-8" :color="['#568aea', '#568aea']" />
            <div class="title">
              <span class="title-text" @click="pageClick()">
                <img
                  src="@/assets/logo.png"
                  width="40px"
                  alt=""
                  style="margin-right:10px;"
                />
                国华众联SaaS平台大数据
                <span class="systemBtn" @click="() => visible = true">系统入口</span>
                </span
              >
              <dv-decoration-6
                class="dv-dec-6"
                :reverse="true"
                :color="['#50e3c2', '#67a1e5']"
              />
            </div>
            <dv-decoration-8
              class="dv-dec-8"
              :reverse="true"
              :color="['#568aea', '#568aea']"
            />
          </div>
          <dv-decoration-10 class="dv-dec-10-s" />
        </div>
        <div class="pagecontent">
          <left-data />
          <center-data className="center-page" />
          <right-data />
        </div>
      </div>
    </div>
      <el-dialog :visible.sync="visible" title="系统入口" width="900px">
        <div class="systemCard">
          <el-card>
            <div @click="navidateTo('http://work.test.guohualinks.com/overview')">
              <div class="systemTitle">助益行</div>
              <div class="systemDesc">残疾员工就业信息记录、就业过程监管</div>
            </div>
          </el-card>
          <el-card>
            <div class="systemTitle">同益加</div>
            <div class="systemDesc">残疾人就业资料上传、在岗信息上报等</div>
          </el-card>
          <el-card>
            <div @click="navidateTo('https://homeland.guohualinks.com/home')">
              <div class="systemTitle">馨益通</div>
              <div class="systemDesc">残疾员工管理、电子档案及画像等</div>
            </div>
          </el-card>
          <el-card>
            <div class="systemTitle">善馨小屋</div>
            <div class="systemDesc">残疾人劳动产品展示、零售等</div>
          </el-card>
          <el-card>
            <div @click="navidateTo('https://kf.guohualinks.com/#/persons')">
              <div class="systemTitle">康益复</div>
              <div class="systemDesc">康复计划、档案管理、康复站管理等</div>
            </div>
          </el-card>
        </div>
      </el-dialog>
  </div>
</template>

<script>
import drawMixin from "../../utils/drawMixin"
import { formatTime } from "../../utils/index.js"

import LeftData from "./component/left_data.vue"
import CenterData from "./component/center_data.vue"
import RightData from "./component/right_data.vue"

import "swiper/css/swiper.css"

export default {
  mixins: [drawMixin],
  data() {
    return {
      visible: false,
      swiperOption: {
        loop: true,
        autoplay: {
          delay: 10000,
        },
      },
      timing: null,
      loading: true,
      dateDay: null,
      dateYear: null,
      dateWeek: null,
      weekday: ["周日", "周一", "周二", "周三", "周四", "周五", "周六"],
      isPageShow: 0,
    }
  },
  components: {
    LeftData,
    CenterData,
    RightData,
  },
  mounted() {
    this.timeFn()
    this.cancelLoading()
  },
  beforeDestroy() {
    // clearInterval(this.timing)
  },
  methods: {
    timeFn() {
      this.timing = setInterval(() => {
        this.dateDay = formatTime(new Date(), "HH: mm: ss")
        this.dateYear = formatTime(new Date(), "yyyy-MM-dd")
        this.dateWeek = this.weekday[new Date().getDay()]
      }, 1000)
    },
    pageClick() {
      if (this.isPageShow == 0) {
        this.isPageShow = 1
      } else if (this.isPageShow == 1) {
        this.isPageShow = 2
      } else if (this.isPageShow == 2) {
        this.isPageShow = 0
      }
    },
    pageShow() {
      var rand = 0

      setInterval(() => {
        this.isPageShow = rand
      }, 5000)
    },

    cancelLoading() {
      setTimeout(() => {
        this.loading = false
      }, 500)
    },
    navidateTo(url) {
      console.log(url);
      window.open(url, '__blank')
    }
  },
}
</script>

<style lang="scss">
@import "@/assets/scss/index.scss";
#app {
  background-color: #022165 !important;
  background-image: linear-gradient(#022165, #0252c3) !important;
}
.bg {
  background-image: linear-gradient(#022165, #0252c3) !important;
}
.bg-color-black {
  background-color: rgba(13, 28, 83, 0.3) !important;
}
.swiper-container {
  width: 100%;

  .swiper-slide {
    width: 100%;
  }
}

.el-dialog {
  z-index: 999999;
}

.systemBtn {
  margin-left: 12px;
  font-size: 16px;
  &:hover {
    cursor: pointer;
  }
}
.v-modal {
  background-color: transparent !important;
  z-index: -1 !important;
}
.el-dialog__wrapper {
  z-index: 9999999999 !important;
}
.systemCard {
  display: flex;
  flex-wrap: wrap;
  .el-card {
    width: 400px;
    margin: 12px;
    &:hover {
      cursor: pointer;
      background-color: #ffb206;
      .systemTitle, .systemDesc {
        color: #fff;
      }
    }
    .systemTitle {
      font-size: 18px;
      font-weight: 500;
      margin-bottom: 6px;
    }
  }
}
.indexPage {
  position: relative;
  overflow: hidden;
  margin: 0px;
  padding: 10px 0 0 0;
}
.pagecontent {
  display: flex;
  justify-content: space-between;
  flex-wrap: nowrap;
  .center-page {
    flex: 1;
  }
}
</style>
