<template>
  <div>
    <Chart :cdata="cdata" :width="width" :height="height" />
  </div>
</template>

<script>
import Chart from "../echart/centerLeft/centerLeft2Chart/chart.vue"
export default {
  props: {
    width: {
      type: String,
      default: "100%",
    },
    height: {
      type: String,
      default: "360px",
    },
  },
  components: {
    Chart,
  },
  data() {
    return {
      cdata: [
        {
          // 名字需要与 “common/map/fujian.js” 地图数据文件里面定义的一一对应，不能是 “福州” 或者 “闽” 之类的缩写
          name: "北京",
          value: 493,
          elseData: {
            // 这里放置地图 tooltip 里想显示的数据
          },
        },
        {
          name: "河北",
          value: 1,
        },
        {
          name: "海南",
          value: 3,
        },
        {
          name: "福建",
          value: 1,
        },
        {
          name: "广东",
          value: 4,
        },
        {
          name: "湖北",
          value: 10,
        },
        {
          name: "湖南",
          value: 3,
        },
        {
          name: "吉林",
          value: 2,
        },
        {
          name: "江苏",
          value: 4,
        },
        {
          name: "江西",
          value: 1,
        },
        {
          name: "辽宁",
          value: 3,
        },
        {
          name: "内蒙古",
          value: 2,
        },
        {
          name: "山东",
          value: 8,
        },
        {
          name: "山西",
          value: 1,
        },
        {
          name: "陕西",
          value: 1,
        },
        {
          name: "四川",
          value: 16,
        },
        {
          name: "云南",
          value: 2,
        },
        {
          name: "浙江",
          value: 48,
        },
        {
          name: "上海",
          value: 39,
        },
        {
          name: "天津",
          value: 11,
        },
        {
          name: "重庆",
          value: 5,
        },

        {
          name: '广西',
          value: 1,
        },
        {
          name: '新疆',
          value: 2,
        }
      ],
    }
  },
  mounted() {},
  methods: {},
}
</script>

<style lang="scss" scoped></style>
